<template>
  <div ref="body">
    <!--头部-->
    <div :class="['header', activeMenu == '/home' ? 'fixed-header' : '']">
      <div class="contain">
        <div class="logo-box">
          <img v-lazy="require('@/assets/home/logo.png')" class="logo-img" />
          <div class="logo-content">
            <div class="title">康美心理</div>
            <div class="intro">康富美互联网医院</div>
          </div>
        </div>
        <div class="flex-box select-box">
          <div class="nav">
            <div class="location"
              :class="[`nav-item`, activeMenu == menu.path ? 'hover' : '']"
              @click="navChange(index,menu.name)"
              v-for="(menu,index) in menus"
              :key="index"
              @mouseenter="over(index)"
              @mouseleave="leave(index)"
            >
              {{ menu.name }} 
              <i v-show="menu.name == '行业解决方案' || menu.name == '医疗机构' || menu.name== '脑科学' || menu.name == '云营销'" class="hover iconfont icon-shangjiantou"></i> 
            </div>
            <!-- 医院 -->
            <div @mouseenter="hospitalOver" @mouseleave="hospitalLeave" v-show="hospitals" class="hospital">
              <div @click="nets">互联网医院</div>
              <div @click="entity">实体医疗机构</div>
            </div>
            <!-- 解决方案 -->
            <div @mouseenter="progammes" @mouseleave="progammesLeave" v-show="programme" class="programme">
              <div @click="orgs">心理咨询机构</div>
              <div @click="schools">教育机构</div>
              <div @click="companys">政企EAP</div>
            </div>
            <!-- 云营销 -->
            <div @mouseenter="marketings" @mouseleave="marketingLeave" v-show="marketing" class="marketing">
              <div @click="shopMall">用户</div>
              <div @click="userRegister">商家</div>
            </div>
            <!-- 资讯 -->
            <div @mouseenter="realTimeInfos" @mouseleave="realTimeInfoLeave" v-show="realTimeInfo" class="realTime-info">
              <div>短视频</div>
              <div @click="img_page">图文</div>
              <div>直播</div>
            </div>
          </div>
          <button class="register-btn" @click="gotoLogin">
            免费试用
          </button>
          <button class="login-btn" @click="gotoLogin">登录</button>
          <button @mouseenter="registerOver" @mouseleave="registerLeave" style="margin-left:10px;" class="login-btn">注册</button>
          <div @mouseenter="registerListOver" @mouseleave="registerListLeave"  v-show="register"  class="register">
            <div @click="userRegister">用户注册</div>
            <div @click="userRegister">咨询师注册</div>
            <div @click="physicianRegister">医师注册</div>
            <div @click="userRegister">商户注册</div>
          </div>
        </div>
      </div>
    </div>
    <!--内容-->
    <transition name="rFade">
      <router-view :class="activeMenu == '/home' ? 'padding-top' : ''" />
    </transition>
    <!--右侧浮窗-->
    <div class="contact">
      <el-popover placement="left" width="150" trigger="hover">
        <div class="title">
          <i class="el-icon-phone-outline el-icon"></i>咨询电话
        </div>
        <div class="call-phone">400-6556-376</div>
        <div class="contact-img phone" slot="reference"></div>
      </el-popover>
      <div class="contact-img test" @click="gotoLogin"></div>
      <el-popover placement="left" width="150" trigger="hover">
        <div class="title">
          <i class="el-icon-chat-dot-round el-icon"></i>联系我们
        </div>
        <img
          :src="require('@/assets/home/weixin1.png')"
          style="width: 120px; height: 120px"
        />
        <div class="contact-img weChat" slot="reference"></div>
      </el-popover>
      <transition name="top">
        <div class="contact-img goTop" @click="goTop" v-if="showGoTop"></div>
      </transition>
    </div>
    <!--底部-->
    <div class="bottom">
      <div class="bottom-box">
        <div>
          <div class="bottom-img">
            <img v-lazy="require('@/assets/home/bottomLogo.png')" />
          </div>
          <div class="bottom-intro">
            <div>
              公司地址：北京市西城区农业部农研中心3层<br>
              <span style="padding-left:74px">浙江省杭州市未来科技城数字健康产业园10幢A座7层</span>
            </div>
            <div>商务合作：13067953119</div>
            <div>商务电话：400-6556-376</div>
            <div>电子邮箱：13067953119@163.com</div>
            <div>
              Copyright © 2022-至今 康美心理 All Rights Reserved.
              <a
                href="https://beian.miit.gov.cn/"
                target="_blank"
                style="text-decoration: none; color: #737377"
                >浙ICP备2022026803号-1</a
              >

            </div>
          </div>
        </div>
        <div class="scan-box">
          <div>
            <div>扫码关注公众号</div>
            <div style="font-weight: bold">“康美心理平台”</div>
          </div>
          <img v-lazy="require('@/assets/home/scan.jpg')" class="scan-img" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Home",
  data() {
    return {
      // 医院flog
      hospitals:false,
      // 解决方案flog
      programme:false,
      // 注册
      register:false,
      //云营销
      marketing:false,
      //脑科学
      realTimeInfo:false,
      menus: [
        {id:0,path:'/home',name:"首页"},
        {id:1,path:'/consult',name:"医疗机构"},//医疗机构
        {id:2,path:'/org',name:"行业解决方案"}, // 行业解决方案
        {id:3,path:null,name:"云营销"},
        {id:4,path:null,name:"脑科学"},
      ],
      showGoTop: false,
    };
  },
  computed: {
    activeMenu() {
      const route = this.$route;
      const { meta, path } = route;
      if (path) {
        return path;
      }
      return path;
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll, true);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll, true);
  },
  methods: {
    //用户跳转进去商城
    shopMall(){
      window.open('https://drugstore.kfmlkmxl.cn/static/html/pc.html')
    },
    //图文
    img_page(){
      window.open('https://cms.kfmlkmxl.cn/')
    },
    // 实体医院
    entity(){
      this.$router.replace('/consult')
      this.menus[1].path = '/consult'
    },
    // 互联网医院
    nets(){
      this.$router.replace('/interHospital')
      this.menus[1].path = '/interHospital'
    },
    // 心理咨询机构
    orgs(){
      this.$router.replace('/org')
      this.menus[2].path = '/org'
    },
    // 云营销
    marketings(){
      this.marketing = true;
    },
    marketingLeave(){
      this.marketing = false;
    },
    // 教育机构
    schools(){
      this.$router.replace('/school')
      this.menus[2].path = '/school'
    },
    // 政企
    companys(){
      this.$router.replace('/company')
      this.menus[2].path = '/company'
    },
    // 注册
    registerOver(){
      this.register = true;
    },
    registerLeave(){
      this.register = false;
    },
    registerListOver(){
      this.register = true;
    },
    registerListLeave(){
      this.register = !this.register
    },
    // 用户等三个注册
    userRegister(){
      this.$router.push('/login')
    },
    // 医师注册
    physicianRegister(){
      this.$router.replace('/interHospital')
      this.menus[1].path = '/interHospital'
    },
    //资讯悬浮
    realTimeInfos(){
      this.realTimeInfo = true
    },
    realTimeInfoLeave(){
      this.realTimeInfo = false
    },
    //over
    over(e){
      switch (e){
        case 1:
          this.hospitals = true
          break;
        case 2:
          this.programme = true
          break;
        case 3:
          this.marketing = true
          break;
        case 4:
          this.realTimeInfo = true
          break;
      }
    },
    leave(e){
      switch (e) {
        case 1:
          this.hospitals = false
          break;
        case 2:
          this.programme = false
          break;
        case 3:
          this.marketing = false;
          break;
        case 4:
          this.realTimeInfo = false
          break;
      }
    },
    hospitalOver(){
      this.hospitals = true
    },
    hospitalLeave(){
      this.hospitals = false
    },
    // 培训机构悬浮
    progammes(){
      this.programme = true
    },
    // 培训机构悬浮离开
    progammesLeave(){
      this.programme = false
    },
    //前往登录
    gotoLogin() {
      this.$router.push("/login");
    },
    //路由切换
    navChange(i,name) {
      this.num = i
      localStorage.setItem('num',i)
      this.num = localStorage.getItem("num")
      switch (name) {
        case '首页':
          this.$router.replace('/home')
          break;
        case '医疗机构':
          this.$router.replace('/interHospital')
          break;
        case '康富商城':
          window.open('https://drugstore.kfmlkmxl.cn/admin/home/')
          break;
        case '资讯':
          window.open('https://cms.kfmlkmxl.cn/')
          break;
        case '行业解决方案':
          this.$router.replace('/org')
          break;
      }

    },
    //回到顶部
    goTop() {
      this.$nextTick(() => {
        this.$refs["body"].scrollIntoView({
          block: "start",
          behavior: "smooth",
        });
      });
    },
    //判断是否显示回到顶部
    handleScroll() {
      let scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      this.showGoTop = scrollTop >= 600 ? true : false;
    },
  },

};
</script>
<style scoped lang="scss">
.fixed-header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  .contain {
    min-width: $width;
    max-width: $width;
  }
}

.padding-top {
  padding-top: 80px;
}

.header {
  width: 100%;
  height: 80px;
  @include flex($justify: center);
  background: #fff;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  .select-box{
    position: relative;
    .over3{
      position: absolute;
      z-index: 777;
      right: 230px;
      top: 50px;;
      width: 300px;
      background-color: #fff;
      border: 1px solid #c3c3c3;
      border-radius: 5px;
      margin-top: 5px;
      padding-top: 10px;
      padding-bottom: 10px;
       div{
        padding: 10px 10px;
      }
      div:hover{
        color:#536cfe;
      }
    }
    .over{
      position: absolute;
      z-index: 777;
      left: 50px;
      top: 50px;;
      width: 300px;
      height: 100px;
      background-color: #fff;
      border: 1px solid #c3c3c3;
      border-radius: 5px;
      margin-top: 5px;
      padding-top: 10px;
      padding-bottom: 10px;
      div{
        // margin:15px 5px;
        padding: 10px 10px;
      }
      div:hover{
        color:#536cfe;
      }
    }
    .select{
      position: absolute;
      top: 50px;
      left: 200px;
      z-index: 999999999;
      p{
        margin: 0;
        margin: 4px 0;
        border-bottom: 1px solid #536cfe;
      }
      .select-color{
        color: #536cfe;
      }
    }
    .select p:hover{
      color: #536cfe;
    }
  }
  .contain {
    width: $width;
    height: 100%;
    @include flex($justify: space-between);
    .logo-box {
      @include flex;
      .logo-img {
        width: 54px;
        height: 54px;
      }
      .logo-content {
        margin-left: 10px;
        .title {
          font-size: 30px;
          color: $title-color;
          font-weight: bold;
          line-height: 33px;
        }
        .intro {
          font-size: 18px;
          color: $intro-color;
          line-height: 20px;
          font-weight: bold;
          margin-top: 4px;
        }
      }
    }

    .nav {
      @include flex;
      height: 80px;
      position: relative;
      // 医院隐藏样式
      .hospital{
        width: 150px;
        position: absolute;
        top: 80px;
        left: 62px;
        overflow: hidden;
        transition: all linear .3s;
        background-color: #222222;
        color: #fff;
        box-sizing: border-box;
        padding: 10px 0;
        z-index: 77777777;
        div{
          height: 40px;
          line-height: 40px;
          text-indent: 10px;
        }
        div:hover{
          background-color: #444444;
        }
      }
      // 解决方案隐藏样式
      .programme{
        width: 150px;
        position: absolute;
        top: 80px;
        left: 170px;
        overflow: hidden;
        transition: all linear .3s;
        background-color: #222222;
        color: #fff;
        box-sizing: border-box;
        padding: 10px 0;
        z-index: 77777;
        div{
          height: 40px;
          line-height: 40px;
          text-indent: 10px;
        }
        div:hover{
          background-color: #444444;
        }
      }
      //云营销
      .marketing{
        width: 150px;
        position: absolute;
        top: 80px;
        left: 310px;
        overflow: hidden;
        transition: all linear .3s;
        background-color: #222222;
        color: #fff;
        box-sizing: border-box;
        padding: 10px 0;
        z-index: 77777;
        div{
          height: 40px;
          line-height: 40px;
          text-indent: 10px;
        }
        div:hover{
          background-color: #444444;
        }
      }

      // 资讯隐藏样式
      .realTime-info{
        width: 150px;
        position: absolute;
        top: 80px;
        left: 395px;
        overflow: hidden;
        transition: all linear .3s;
        background-color: #222222;
        color: #fff;
        box-sizing: border-box;
        padding: 10px 0;
        z-index: 77777;
        div{
          height: 40px;
          line-height: 40px;
          text-indent: 10px;
        }
        div:hover{
          background-color: #444444;
        }
      }
      .location{
        position: relative;
        i{
          position: absolute;
          right: -18px;
          top: -4px;
          font-size: 16px;
          transform: rotate(-180deg);
          transition:  all linear .2s;
        }
      }
      .nav-item {
        position: relative;
        height: 80px;
        line-height: 80px;
        font-size: 16px;
        color: $title-color;
        padding-bottom: 5px;
        text-align: center;
      }
      .nav-item {
        margin-right: 40px;
      }
      .nav-item::after {
        position: absolute;
        content: "";
        bottom: 1px;
        top: 60px;
        left: 50%;
        width: 0;
        height: 3px;
        border-radius: 30px;
        -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        transform: translateX(-50%);
        background-color: $color;
        -webkit-transition: all 0.25s ease-in-out;
        -o-transition: all 0.25s ease-in-out;
        transition: all 0.25s ease-in-out;
      }
      .nav-item:hover::after {
        width: 100%;
      }
      .nav-item:hover,
      .nav-item.hover {
        color: $color;
        i{
          transform: rotate(-360deg);
          color: #536cfe;
        }
      }
    }

    .login-btn {
      width: 80px;
      line-height: 36px;
      height: 36px;
      border: 1px solid $color;
      border-radius: 50px;
      text-align: center;
      color: $color;
      background: #fff;
    }

    .login-btn:hover {
      background: $color;
      color: #fff;
    }

    .register-btn {
      width: 120px;
      height: 36px;
      line-height: 36px;
      background: rgba(250, 196, 111, 0.7);
      border-radius: 20px;
      color: $title-color;
      font-weight: 500;
      text-align: center;
      margin-right: 15px;
    }

    .register-btn:hover {
      background: $color2;
    }
    .register{
      width: 150px;
      height: auto;
      overflow: hidden;
      position: absolute;
      top: 60px;
      right: -76px;
      overflow: hidden;
      transition: all linear .3s;
      background-color: #222222;
      color: #fff;
      box-sizing: border-box;
      padding: 10px 0;
      z-index: 77777777;
      border-radius: 5px 5px 0 0;
      div{
        height: 40px;
        line-height: 40px;
        text-indent: 10px;
      }
      div:hover{
        background-color: #444444;
      }
    }
  }
}

.bottom {
  width: 100%;
  box-shadow: 0px -2px 6px rgba(0, 0, 0, 0.1);
  margin-top: 80px;
  @include flex($justify: center);
  .bottom-box {
    width: $width;
    padding: 40px 0;
    @include flex($justify: space-between);

    .bottom-img {
      display: flex;
      width: 475px;
      height: 40px;
      margin-bottom: 20px;
      img {
        height: 100%;
        background: url(../../assets/home/bottomLogo.png) no-repeat;
      }
    }

    .bottom-intro {
      font-size: 15px;
      color: $intro-color;
      line-height: 24px;
    }
  }

  .scan-box {
    @include flex;
    color: $title-color;
    line-height: 24px;
    .scan-img {
      width: 140px;
      height: 140px;
      margin-left: 60px;
    }
  }
}

.contact {
  height: 288px;
  position: fixed;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  z-index: 9999;

  .contact-img {
    width: 70px;
    height: 70px;
    background-size: 70px 70px;
    border-radius: 4px;
    margin-bottom: 2px;
  }

  .phone {
    background-image: url("../../assets/home/phone.png");
  }

  .test {
    background-image: url("../../assets/home/test.png");
  }

  .weChat {
    background-image: url("../../assets/home/contact.png");
  }

  .goTop {
    background-image: url("../../assets/home/goTop.png");
  }
}

.el-popper {
  .el-icon {
    font-size: 18px;
    margin-right: 4px;
  }
  .title {
    font-size: 15px;
    color: $title-color;
    font-weight: bold;
    margin-bottom: 8px;
  }
  .call-phone {
    font-size: 15px;
    line-height: 24px;
    color: $color;
  }
}
</style>
